<template>
  <v-row class="ma-2" style="max-width: 50%;">
    <v-form ref="form" class="w-100">
      <div class="d-flex align-center justify-space-between ma-4 w-100 pr-4">
        <v-btn
          icon
          @click="$router.push({ name: 'Contacts' })"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          :disabled="disableSubmit"
          :loading="loadingAdd"
          color="primary"
          elevation="0"
          rounded
          class="font-weight-medium"
          @click="$route.name === 'NewContact' ? addPeople() : updatePeople()"
        >
          Save
        </v-btn>
      </div>
      <div class="ml-6">
        <div class="d-flex align-center mb-5">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            location="bottom"
            offsetY
            attach
          >
            <template v-slot:activator="{ props }">
              <v-btn
                height="28px"
                color="primary"
                class="text-none px-3 py-0 label-button"
                v-bind="props"
                @click="menu = true"
              >
                <v-icon left>mdi-plus</v-icon>
                Label
              </v-btn>
            </template>
            <v-card min-width="384">
              <v-card-title class="fz-14 font-weight-medium pb-0">Manage labels</v-card-title>
              <div class="label-wrapper">
                <v-list>
                  <v-list-item
                    v-for="(label, index) in labels"
                    :key="index + 'label'"
                    class="label-item"
                    @click="selectLabel(label.uuid)"
                  >
                    <v-icon>mdi-label-outline</v-icon>
                    <span class="ml-5">{{ label.label }}</span>
                    <v-icon v-if="isSelected(label.uuid)" style="margin-left:auto">mdi-check</v-icon>
                    <v-icon v-else style="margin-left:auto">mdi-plus</v-icon>
                  </v-list-item>
                </v-list>
              </div>
              <hr/>
              <v-card-actions>
                <v-btn
                  elevation="0"
                  color="primary"
                  class="text-none"
                  @click="openCreateLabelDialog"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  <span class="font-weight-regular">Create label</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  elevation="0"
                  class="text-none"
                  outlined
                  @click="menu = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :disabled="!tempLabels.length"
                  color="primary"
                  elevation="0"
                  class="text-none"
                  @click="setSelectedLabels"
                >
                  Apply
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <div
            v-if="form.label_uuid.length"
            class="ml-3 d-flex align-center flex-wrap gap-2"
            style="gap:10px"
          >
            <v-chip
              v-for="(uuid, index) in form.label_uuid"
              :key="index + 'label'"
              class="label-chip"
              close
              @click:close="removeLabel(uuid)"
            >
              {{ getLabelName(uuid) }}
            </v-chip>
          </div>
        </div>
        <v-text-field
          v-model="form.first_name"
          dense
          :rules="form.last_name ? [] : firstNameRules"
          height="36px"
          background-color="#fafafa"
          flat
          outlined
          solo
          required
          placeholder="First name"
          prepend-icon="mdi-account-outline"
          @keypress="onlyLettersAndNumbers"
        ></v-text-field>
        <v-text-field
          v-model="form.last_name"
          :rules="form.first_name ? [] : lastNameRules"
          dense
          height="36px"
          background-color="#fafafa"
          flat
          outlined
          solo
          required
          placeholder="Last name"
          class="ml-8"
          @keypress="onlyLettersAndNumbers"
        ></v-text-field>
        <v-text-field
          v-model="phoneNumber"
          :maxlength="hasCountryCode ? 17 : 14"
          dense
          height="36px"
          background-color="#fafafa"
          flat
          outlined
          solo
          required
          placeholder="Phone"
          prepend-icon="mdi-phone"
          @keypress="onlyNumbers"
        ></v-text-field>
      </div>
    </v-form>
    <v-dialog v-model="createLabelDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Create Label
          <v-spacer></v-spacer>
          <v-btn @click="closeCreateLabelDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="newLabel"
              :rules="labelRules"
              dense
              label="Label"
              outlined
              @keypress="onlyLettersAndNumbers"
              @keypress.enter.prevent="createLabelHandler"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            outlined
            @click="closeCreateLabelDialog"
            >Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            @click="createLabelHandler"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { beautifyNumber } from '../utilities/formatPhone'

export default {
  name: 'NewContact',
  data: () => ({
    loading: false,
    menu: null,
    createLabelDialog: false,
    newLabel: '',
    loadingAdd: false,
    form: {
      first_name: '',
      last_name: '',
      phone_number: '',
      label_uuid: []
    },
    firstNameRules: [v => !!v || 'First name is required'],
    lastNameRules: [v => !!v || 'Last name is required'],
    labelRules: [v => !!v || 'Label field is required'],
    tempLabels: []
  }),
  computed: {
    ...mapGetters('labels', ['labels']),
    disableSubmit () {
      return (!this.form.first_name && !this.form.last_name) || this.form.phone_number.length < 10
    },
    hasCountryCode () {
      try {
        const cleaned = `${this.form.phone_number}`
        const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
        return !!match[1]
      } catch (err) {
        return false
      }
    },
    phoneNumber: {
      get () {
        return this.beautifyNumber(this.form.phone_number)
      },
      set (val) {
        this.form.phone_number = val.replace(/\D/g, '')
      }
    }
  },
  watch: {
    menu (val) {
      if (val) this.tempLabels = [...this.form.label_uuid]
      else {
        setTimeout(() => {
          this.tempLabels = []
        }, 200)
      }
    }
  },
  created () {
    this.getLabels()
    if (this.$route.name === 'EditContact') this.getContact()
  },
  methods: {
    ...mapActions('contacts', ['createContact', 'updateContact', 'getContactbyUuid']),
    ...mapActions('labels', ['getLabels', 'createLabel']),
    ...mapActions(['showAlert']),
    beautifyNumber,
    selectLabel (uuid) {
      if (this.tempLabels.includes(uuid)) {
        this.tempLabels = this.tempLabels.filter(e => e !== uuid)
      } else {
        this.tempLabels.push(uuid)
      }
    },
    isSelected (uuid) {
      return this.tempLabels.includes(uuid)
    },
    openCreateLabelDialog () {
      this.menu = false
      this.createLabelDialog = true
    },
    closeCreateLabelDialog () {
      this.createLabelDialog = false
      this.newLabel = ''
    },
    setSelectedLabels () {
      this.form.label_uuid = [...this.tempLabels]
      this.menu = false
    },
    removeLabel (uuid) {
      this.form.label_uuid = this.form.label_uuid.filter(e => e !== uuid)
    },
    getLabelName (uuid) {
      const label = this.labels.find(e => e.uuid === uuid)
      return label ? label.label : '-'
    },
    async getContact () {
      try {
        const data = await this.getContactbyUuid(this.$route.params.uuid)
        if (!data) return
        this.form.first_name = data.first_name
        this.form.last_name = data.last_name
        this.form.phone_number = data.phone_number
        const labels = data.userLabel.map(e => e.uuid)
        this.form.label_uuid = labels
        this.tempLabels = labels
      } catch (err) {
        throw new Error(err)
      }
    },
    async createLabelHandler() {
      try {
        // console.log(this.$refs.form, 'this.$refs.form')
        const isValid = await this.$refs.form.validate()
        if (!isValid) return
        const data = await this.createLabel({ label: this.newLabel })
        this.showAlert({ message: 'Label was successfully created' })

        this.closeCreateLabelDialog()
        this.getLabels()
      } catch (err) {
        // this.showAlert({ error: `Sorry, there was something wrong while   ${this.isEdit ? 'editing' : 'creating'} label` })
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    },
    async updatePeople() {
      try {
        this.loadingAdd = true
        const success = await this.updateContact({
          uuid: this.$route.params.uuid,
          payload: this.form
        })

        if (!success) return
        this.showAlert({ message: 'Contact was successfully updated' })
        setTimeout(() => {
          this.$router.push({ name: 'Contacts' })
        }, 3000)
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingAdd = false
      }
    },
    async addPeople() {
      try {
        const isValid = this.$refs.form.validate()

        if (!isValid) return
        this.loadingAdd = true
        const payload = {
          first_name: this.form.first_name,
          phone_number: this.form.phone_number
        }

        if (this.form.last_name) payload.last_name = this.form.last_name
        if (this.form.label_uuid.length) payload.label_uuid = this.form.label_uuid

        const success = await this.createContact(payload)

        if (!success) return
        this.showAlert({ message: 'Contact was successfully created' })
        setTimeout(() => {
          this.$router.push({ name: 'Contacts' })
        }, 3000)
      } catch (err) {
        this.showAlert({ error: 'Sorry, there was something wrong while creating contact' })
        throw new Error(err)
      } finally {
        this.loadingAdd = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label-button {
  border-radius: 8px;
  border-color: #808080b7;
  ::v-deep .v-btn__content {
    font-weight: 500;
    font-size: 11px;
  }
}
.label-wrapper {
  max-height: calc(90vh - 240px);
  overflow-y: auto;
}
.label-item {
  cursor: pointer;
  &:hover {
    background: #d3dbe5;
  }
}
</style>
